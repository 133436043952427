import React, { useEffect,Suspense, lazy, useState, useCallback } from 'react';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { FaHome, FaUsers, FaCube, FaGamepad, FaPlay,FaLanguage , FaPaintBrush, FaTachometerAlt, FaCamera, FaRuler, FaToolbox, FaPaperPlane } from 'react-icons/fa';
import { CgTemplate } from 'react-icons/cg';
const Placeholder = () => <div>Loading...</div>;

const Users = lazy(() => import('../components/Docs/Users'));
const Scenarios = lazy(() => import('../components/Docs/Scenarios'));
const Godot = lazy(() => import('../components/Docs/Godot'));
const GettingStarted = lazy(() => import('../components/Docs/GettingStarted'));
// const CreateScenarios = lazy(() => import('../components/Docs/CreateScenarios'));
const Languages = lazy(() => import('../components/Docs/Languages'));
const Templates = lazy(() => import('../components/Docs/Templates'));
const Intro = lazy(() => import('../components/Docs/Intro'));
const DesignScenarios = lazy(() => import('../components/Docs/DesignScenarios'));
const Speed = lazy(() => import('../components/Docs/Speed'));
const ScreenShots = lazy(() => import('../components/Docs/ScreenShots'));
const Maneuver = lazy(() => import('../components/Docs/Maneuver'));
const Toolbar = lazy(() => import('../components/Docs/Toolbar'));
const SubmitScenario = lazy(() => import('../components/Docs/SubmitScenario'));


export default function Docs() {
  const [selectedTab, setSelectedTab] = useState('intro');
  const [isWelcomeSubMenuVisible, setWelcomeSubmenuVisible] = useState('');
  const [isGettingStartedSubMenuVisible, setGettingStartedSubmenuVisible] = useState('none');
  const [isDesignScenariosSubMenuVisible, setDesignScenariosSubmenuVisible] = useState('none');
  const components = {
    intro: Intro,
    users: Users,
    scenarios: Scenarios,
    godot: Godot,
    'getting-started': GettingStarted,
    // 'create-scenario': CreateScenarios,
    languages: Languages,
    templates: Templates,
    'design-scenarios': DesignScenarios,
    speed:Speed,
    'screen-shots': ScreenShots,
    maneuver: Maneuver,
    toolbar: Toolbar,
    'submit-scenario': SubmitScenario
  };

  const handleSelect = useCallback((key) => {
    setSelectedTab(key);
    window.location.hash = key; // Update the URL hash

  }, []);
  
  const showWelcomeSubMenu = useCallback(() => {
    setGettingStartedSubmenuVisible('none');
    setDesignScenariosSubmenuVisible('none');
    setWelcomeSubmenuVisible(prev => prev === 'none' ? '' : 'none');

  }, []);
  const showGettingStartedSubMenu = useCallback(() => {
    setWelcomeSubmenuVisible('none');
    setDesignScenariosSubmenuVisible('none');
    setGettingStartedSubmenuVisible(prev => prev === 'none' ? '' : 'none');

  }, []);
  const showDesignScenariosSubMenu = useCallback(() => {
    setWelcomeSubmenuVisible('none'); 
    setDesignScenariosSubmenuVisible(prev => prev === 'none' ? '' : 'none');
  }, []);

  const showSubmitScenarioSubMenu = useCallback(() => { 
    setWelcomeSubmenuVisible('none');
    setGettingStartedSubmenuVisible('none');
    setDesignScenariosSubmenuVisible('none');

  }, []);
  
  const Component = components[selectedTab];

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.replace('#', '');
      if (hash && components[hash]) {
        setSelectedTab(hash);
      }
    };

    // Check the initial hash
    handleHashChange();

    // Add event listener for hash changes
    window.addEventListener('hashchange', handleHashChange);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, [components]);
  return (
    <div className="content-wrapper container">
      <Tab.Container id="docs_tabs" activeKey={selectedTab} onSelect={handleSelect}>
        <Row>
          <Col sm={2}>
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link onClick={showWelcomeSubMenu} eventKey="intro" className="nav-link-background-orange">
                 <FaHome/>  Welcome 
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="users" style={{display:isWelcomeSubMenuVisible}} className="nav-link-background-orange nav-link-submenu">
                <FaUsers /> Users
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="scenarios" style={{display:isWelcomeSubMenuVisible}} className="nav-link-background-orange nav-link-submenu">
                <FaCube /> Scenarios
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="godot" style={{display:isWelcomeSubMenuVisible}} className="nav-link-background-orange nav-link-submenu">
                <FaGamepad /> Godot Engine
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link onClick={showGettingStartedSubMenu} eventKey="getting-started" className="nav-link-background-orange">
                <FaPlay /> Getting Started
                </Nav.Link>
              </Nav.Item>
        
              <Nav.Item>
                <Nav.Link eventKey="languages" style={{display:isGettingStartedSubMenuVisible}} className="nav-link-background-orange nav-link-submenu">
                <FaLanguage /> Languages
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="templates" style={{display:isGettingStartedSubMenuVisible}} className="nav-link-background-orange nav-link-submenu">
                <CgTemplate /> Templates
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link onClick={showDesignScenariosSubMenu} eventKey="design-scenarios" style={{display:isGettingStartedSubMenuVisible}} className="nav-link-background-orange nav-link-submenu">
                <FaPaintBrush /> Design Scenarios
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="speed" style={{display:isDesignScenariosSubMenuVisible}} className="nav-link-background-orange nav-link-submenu2">
                <FaTachometerAlt /> Speed
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="screen-shots" style={{display:isDesignScenariosSubMenuVisible}}  className="nav-link-background-orange nav-link-submenu2">
                <FaCamera /> Screenshots
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="maneuver" style={{display:isDesignScenariosSubMenuVisible}}  className="nav-link-background-orange nav-link-submenu2">
                <FaRuler /> Manoeuvre
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="toolbar" style={{display:isDesignScenariosSubMenuVisible}}  className="nav-link-background-orange nav-link-submenu2">
                <FaToolbox /> Toolbar
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link onClick={showSubmitScenarioSubMenu} eventKey="submit-scenario" className="nav-link-background-orange">
                <FaPaperPlane /> Submit Scenario
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={10}>
            <Tab.Content>
              <Suspense fallback={<Placeholder />}>
                <Component />
              </Suspense>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
     
    </div>
  );
}
