import "../assets/css/index.css";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function About() {
    return (
        <>
            <div className="content-wrapper container">
                <br />
                <Row>
                    <h1 style={{ fontSize: '30px' }} className='orange-color-font font-family-RalewayExtraBold'>
                      
                            About us
                     
                    </h1>
                    <br />
                </Row>
                <br />
                <Row>

                    <Col>
                        <div>

                            <p >
                                Safety Pool™ Studio is the world’s first publicly accessible scenario creation tool. Developed by the Safe Autonomy research group at WMG, The University of Warwick, this tool allows the general public and non-technical users in the self-driving ecosystem to design and run testing scenarios for Automated Vehicles (AV).
                            </p>

                            <p>
                                Virtual scenarios and scenario databases are commonly used within the self-driving ecosystem to test the safety level of their self-driving systems. Most databases only store the scenarios in programmatic languages, which are only accessible by engineers or people with technical knowledge.
                            </p>


                        </div>
                    </Col>
                    <Col>
                        <div>
                            <p>
                                The general public is often left out of the safety assurance process and safety conversation. For our research team, the public (as end users) is paramount to the AV deployment. We believe the public needs to have a voice in the AV development journey to enable their trust and enhance their acceptance of the technology.                             </p>
                            <p>
                                By engaging with Safety Pool™ Studio, every member of the public and the ecosystem will have an equal opportunity to create scenarios using a graphical interface, in a LEGO-like style. This would enable the public to share their concerns and expectations about safety by creating scenarios in a simple, accessible, and inclusive manner.
                            </p>

                        </div>
                    </Col>
                </Row>

            </div>

        </>
    )
}