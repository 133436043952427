import "../assets/css/index.css";

export default function Privacy() {
    return (
     
            <div className="content-wrapper container">
                <h1 style={{ fontSize: '30px' }} className="orange-color-font font-family-RalewayExtraBold">Privacy Policy</h1>
                <div >
                <p>Last updated: January 04, 2024</p>
                <p>When processing your personal data [your ‘information’] the University of Warwick acts as a Data Controller. Our Data Protection Officer can be contacted at <a href="mailto:dpo@warwick.ac.uk">dpo@warwick.ac.uk</a>.</p>
                <p>Your information is processed for the purpose(s) of registering you and others as a user of our services and to communicate with you. If you are registering others to use our services please draw their attention to this notice.</p>
                <p>The University will collect and process the following types of information about you; name, email and telephone number.</p>
                <p>The legal basis for processing is to take steps at your behalf with a view to service provision and to later provide the service.</p>
                <p>Your information will be kept securely by the University and retained for seven years after you cease to use the service. Please visit <a href="https://warwick.ac.uk/services/idc/dataprotection" target="_blank" rel="noreferrer">https://warwick.ac.uk/services/idc/dataprotection</a> for further information in relation to your rights and how the University processes your information.</p>
                </div>
            </div>

     
    )
}