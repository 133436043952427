import '../assets/css/index.css';
import React, { useState } from 'react';
import {Server_URL,feedBack_URL} from '../constants/constants'


function FeedbackPage() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [image, setImage] = useState({ preview: '', data: '' });
  const [isSubmissionSuccessful, setIsSubmissionSuccessful] = useState(false); // Add this state

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('message', message);
    formData.append('file', image.data);


    try {
      const token = document.cookie.replace(/(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/, '$1');

      const response = await fetch(`${Server_URL}${feedBack_URL}`, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        setIsSubmissionSuccessful(true);
      } else {
        // Handle errors
        // console.log('Request failed with status:', response.status);
      }
    } catch (error) {
      // console.error('Error:', error);
    }
  };

  const handleFileChange  = (e) => {
    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    }
    setImage(img)
  
  };

  return (
    <>
      {isSubmissionSuccessful ? (
        <p>
          <span className='bug-text'>Thank you very much for submitting your feedback!</span></p> // Display the success message
      ) : (


        <div>
          <form onSubmit={handleSubmit} encType='multipart/form-data'>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                className='form-control'
          

              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className='form-control'
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message:</label>  <br />

            </div>
            <div>
              <textarea
                className="form-control"
                id="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                rows="3"
                required
              />
            </div>

            <br />

            <div >

              <label htmlFor="file">Attachment (Supporting only one file uploaded at the moment)</label>

              <input  className="form-control" type="file" id="file" name="file" onChange={handleFileChange} multiple />

            </div>
            <hr />
            <div style={{ textAlign: 'right' }}>
              <button className='btn orange-color circule' type="submit">Submit</button>
          
            </div>
          </form>
        </div>
      )}
    </>

  );
}

export default FeedbackPage;
