import React, { useState, useEffect } from 'react';
import { Container, Col } from "react-bootstrap"
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { isAuthenticated as checkIsAuthenticated } from './admin/isAuthenticated';
import LogoSC from '../images/logos/logoSc.png'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Role } from '../constants/constants';
import { FaHome, FaInfoCircle, FaBook, FaShieldAlt, FaQuestionCircle, FaSignOutAlt, FaTachometerAlt } from 'react-icons/fa';

export default function NavbarMenu() {
  const navigate = useNavigate();
  const [isAuthenticatedState, setIsAuthenticatedState] = useState(false);
  const [isAdminRole, setIsAdminRole] = useState(false);


  const handleLogout = () => {
    Cookies.remove('token');
    setIsAuthenticatedState(false);
    navigate('/login');
  };


  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const result = await checkIsAuthenticated();

        const status = result.status

        if (status) {
          setIsAuthenticatedState(true);
          let role = result.role;
          if (role === Role.admin) {
            setIsAdminRole(true);
          }
        } else {
          setIsAuthenticatedState(false);
        }
      } catch (error) {
        // console.error('Error checking authentication:', error);
      }
    };

    checkAuthentication();
  }, [navigate]);


  return (

    <Container>
      <div>
        <Navbar expand="lg">


          <Col xs={12} md={5}>
            <Navbar.Brand className="font-style" href="/">
              <img src={LogoSC} width={'450px'} alt="Logo" />
            </Navbar.Brand>
          </Col>


          <Col xs={12} md={7} className="navbar-links-col">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="justify-content-end" style={{ width: "100%" }}>
                <Nav.Link href="/"><FaHome className="me-2" /> <b> Home</b></Nav.Link>
                <Nav.Link href="/about"><FaInfoCircle className="me-2" /><b>About</b> </Nav.Link>
                <Nav.Link href="/tutorial"> <FaBook className="me-2" /> <b>Tutorial</b></Nav.Link>
                <Nav.Link href="/privacy"><FaShieldAlt className="me-2" /><b>Privacy</b> </Nav.Link>
                <Nav.Link href="/docs"><FaQuestionCircle className="me-2" /><b>FAQ</b> </Nav.Link>
                <Nav>
                  {isAuthenticatedState ? (
                    <>
                      <Nav.Link href="/" onClick={handleLogout}>
                      <FaSignOutAlt className="me-2" /> <b>Logout</b> 
                      </Nav.Link>
                      {isAdminRole ? (
                        <>
                          <Nav.Link href="/dashboard"><FaTachometerAlt className="me-2" /><b>Dashboard</b> </Nav.Link>
                        </>
                      ) : null}
                    </>
                  ) : null }
                </Nav>


              </Nav>
            </Navbar.Collapse>
            <hr className="custom-hr" />
          </Col>

        </Navbar>

      </div>
    </Container>

  );
}

