import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { MotionAnimate } from 'react-motion-animate'


const Vision = () => {
    return (
        <div >
            <Row>
                <Col xs={2} md={2}>
                    <div className='orange-color-font big-text' >
                        <b>Vision</b>
                    </div>
                </Col>

                <br />
                <Col>
                <MotionAnimate
                    animation="fadeInUp" 
                    speed={1} 
                    delay={0.5} 
                >
                    <div className='large-text'>
                        <p>
                            Safety Pool™ Studio empowers everyone to design safe and reliable automated driving systems through a user-friendly interface.
                        </p>
                    </div>
                    </MotionAnimate>
                </Col>

            </Row>
        </div>
    );
};

export default Vision;
