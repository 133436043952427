import React, { Suspense, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import LoadingSpinner from '../../components/LoadingSpinner';

// Lazy load the components
const Feedbacklist = React.lazy(() => import('../../components/admin/FeedbackList'));
const Loglist = React.lazy(() => import('../../components/admin/LogList'));
const Organisations = React.lazy(() => import('../../components/admin/Organisations'));
const Scenarios = React.lazy(() => import('../../components/admin/Scenarios'));
const ApplicationInfo = React.lazy(() => import('../../components/admin/ApplicationInfo'));
const ScenarioPieChart = React.lazy(() => import('../../components/admin/ScenarioPieChart'));

function Dashboard() {
  const location = useLocation();
  const { state } = location;
  const passedUsername = state && state.username;

  useEffect(() => {
    
  }, [passedUsername]);


  const tabStyle = {
    color: '#FF6633', 
  };

  return (
    <div className="content-wrapper container">
      <h3> Dashboard </h3>
      <h5>
        In this dashboard, you can view a comprehensive list that includes retrieved feedback, submitted scenarios, audit logs, figures, and more!
      </h5>
      <hr />
      <Tabs
        id="info"
        className="mb-3"
        style={{ fontSize: '20px' }}
      >
        <Tab eventKey="info" title="Info" style={tabStyle}>
   
            <Suspense fallback={<LoadingSpinner />}>
              <ApplicationInfo />
            </Suspense>
      
        </Tab>
        <Tab eventKey="charts" title="Statistics" style={tabStyle}>
        
            <Suspense fallback={<LoadingSpinner />}>
              <ScenarioPieChart />
            </Suspense>
     
        </Tab>
        <Tab eventKey="organisations" title="Demographics" style={tabStyle}>
      
            <Suspense fallback={<LoadingSpinner />}>
              <Organisations />
            </Suspense>
   
        </Tab>
        <Tab eventKey="scenarios" title="Scenarios" style={tabStyle}>
      
            <Suspense fallback={<LoadingSpinner />}>
              <Scenarios />
            </Suspense>
   
        </Tab>
        <Tab eventKey="feedback" title="Feedback" style={tabStyle}>

            <Suspense fallback={<LoadingSpinner />}>
              <Feedbacklist />
            </Suspense>
    
        </Tab>
        <Tab eventKey="logs" title="Logs" style={tabStyle}>
         
            <Suspense fallback={<LoadingSpinner />}>
              <Loglist />
            </Suspense>
     
        </Tab>
      </Tabs>
    </div>
  );
}

export default Dashboard;
