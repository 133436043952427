// Forbidden.js
import React from 'react';
const Forbidden = () => {
  return (
    <div className="content-wrapper container">
      <h1 className='orange-color-font'>Access deined </h1>
      <h3> You do not have the necessary permissions to access this page.
      </h3>
    </div>
  );
};

export default Forbidden;
