// NotFound.js
import React from 'react';

const NotFound = () => {
  return (
    <div className="content-wrapper container">
      <h1  className='orange-color-font'>404 - Not Found</h1>
      <h3>The page you are looking for does not exist.</h3>
    </div>
  );
};

export default NotFound;
