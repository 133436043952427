// Unauthenticated.js
import React from 'react';
const Unauthenticated = () => {
  return (
    <div className="content-wrapper container">
      <h1 className='orange-color-font'>Unauthenticated</h1>
      <h3>You are not authenticated to access this page.</h3>
    </div>
  );
};

export default Unauthenticated;
