// src/MapComponent.js
import React, { useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import 'leaflet-routing-machine';
import RoutingMachine from './RoutingMachine';


import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: markerIcon2x,
    iconUrl: markerIcon,
    shadowUrl: markerShadow,
});





const MapComponent = () => {
    // Define the initial positions of the markers

    const initialPosition1 = [52.38430021799166, -1.5726168254015773];
    const initialPosition2 = [52.384376778746145, -1.5722037547907608];

    // Set up state to manage marker positions
    const [position1, setPosition1] = useState(initialPosition1);
    const [position2, setPosition2] = useState(initialPosition2);

    // Handle marker drag events
    const handleDragEnd1 = (event) => {
        const newPosition = event.target.getLatLng();
        setPosition1([newPosition.lat, newPosition.lng]);
        console.log('Marker 1 position:', newPosition);
    };

    const handleDragEnd2 = (event) => {
        const newPosition = event.target.getLatLng();
        setPosition2([newPosition.lat, newPosition.lng]);
        console.log('Marker 2 position:', newPosition);
    };

    return (
        <MapContainer center={position1} zoom={13} style={{ height: '50%', width: '50%' }}>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker
                position={position1}
                draggable={true}
                eventHandlers={{
                    dragend: handleDragEnd1
                }}
            >
                <Popup>
                    Route Start
                </Popup>
            </Marker>
            <Marker
                position={position2}
                draggable={true}
                eventHandlers={{
                    dragend: handleDragEnd2
                }}
            >
                <Popup>
                    Route End
                </Popup>
            </Marker>
            <RoutingMachine position1={position1} position2={position2} />
        </MapContainer>
    );
};

export default MapComponent;
