import '../assets/css/index.css';
import FeedBackModalPopUp from '../components/FeedBackModalPopUp';
import { useState } from 'react';

import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';

import walkthroughVideo from '../assets/videos/Walkthrough Seamless.mp4';
import demo1Video from '../assets/videos/Demo 1 - How to start from the template.mp4';
import demo2Video from '../assets/videos/Demo 2 - Move and Remove Objects.mp4';
import demo3Video from '../assets/videos/Demo 3 - Using the Road Editor.mp4';
import demo4Video from '../assets/videos/Demo 4 - Saving and Submitting.mp4';

export default function Tutorial() {
  const [activeKey, setActiveKey] = useState('overview');

  return (
    <div className="content-wrapper container">
      <h1 style={{ fontSize: '30px' }} className='orange-color-font font-family-RalewayExtraBold'>
        <b>Tutorial</b>
      </h1>
      <br />
      <Tab.Container id="left-tabs-example" activeKey={activeKey} onSelect={(k) => setActiveKey(k)}>
        <Row>
          <Col sm={3}>
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link eventKey="overview" className="nav-link-background-orange">Overview</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="walkthrough" className="nav-link-background-orange">Walkthrough</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="demo1" className="nav-link-background-orange">Start from the template</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="demo2" className="nav-link-background-orange">Move and remove objects</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="demo3" className="nav-link-background-orange">Using the Road Editor</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="demo4" className="nav-link-background-orange">Saving and Submitting</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={9}>
            <Tab.Content>
              <Tab.Pane eventKey="overview">
                {activeKey === 'overview' && (
                  <div className='video-container'>
                    <div className="video-frame">
                      <video controls poster="/assets/img/blur_bg.png" autoPlay muted>
                        <source src="/assets/img/sc_tutorial_vid.mp4" type="video/mp4" />
                      </video>
                    </div>
                  </div>
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="walkthrough">
                {activeKey === 'walkthrough' && (
                  <div className='video-container'>
                    <div className="video-frame">
                      <video controls poster="/assets/img/blur_bg.png" autoPlay muted>
                        <source src={walkthroughVideo} type="video/mp4" />
                      </video>
                    </div>
                  </div>
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="demo1">
                {activeKey === 'demo1' && (
                  <div className='video-container'>
                    <div className="video-frame">
                      <video controls poster="/assets/img/blur_bg.png" autoPlay muted>
                        <source src={demo1Video} type="video/mp4" />
                      </video>
                    </div>
                  </div>
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="demo2">
                {activeKey === 'demo2' && (
                  <div className='video-container'>
                    <div className="video-frame">
                      <video controls poster="/assets/img/blur_bg.png" autoPlay muted>
                        <source src={demo2Video} type="video/mp4" />
                      </video>
                    </div> 
                  </div>
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="demo3">
                {activeKey === 'demo3' && (
                  <div className='video-container'>
                    <div className="video-frame">
                      <video controls poster="/assets/img/blur_bg.png" autoPlay muted>
                        <source src={demo3Video} type="video/mp4" />
                      </video>
                    </div>
                  </div>
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="demo4">
                {activeKey === 'demo4' && (
                  <div className='video-container'>
                    <div className="video-frame">
                      <video controls poster="/assets/img/blur_bg.png" autoPlay muted>
                        <source src={demo4Video} type="video/mp4" />
                      </video>
                    </div>
                  </div>
                )}
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>

      <div>
        <hr />
        <label style={{ fontSize: '30px' }} className='orange-color-font font-family-RalewayExtraBold'>
          How did we go?
        </label>

        <div>
          <br />
          <p>
            If you find a bug or have any advice, please submit your feedback, including messages, screenshots, and screen recordings through the below form
          </p>
          <FeedBackModalPopUp />
        </div>
      </div>
    </div>
  );
}
