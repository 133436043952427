import React, { useState } from 'react';
import { Server_URL, Login_URL } from '../constants/constants';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const [msg, setMsg] = useState ('');
    const handleRegister = async (e) =>
    {
        setMsg('Registration is not enabled yet.')

    }
    const handleLogin = async (e) => {

        e.preventDefault();

        if (!username || !password) {
            setError('Username and password are required.');
            return;
        }
        try {
            console.log(process.env.REACT_APP_BASE_URL)
            const response = await fetch(`${Server_URL}${Login_URL}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ username, password }),
            });
            
            if (response.ok) {
                // console.log("response",response)

                setError('')

                navigate('/');

            }
            else if (response.status === 401) {
                // console.log("response:", response)
                setError('Invalid username or password ');

            }
            else {
                // console.log("response:", response)
                setError('Internal Server Error');
            }

        } catch (error) {
            // console.log(error)
            setError('Internal Server Error');

        }
    };

    

    return (
        <div className="content-wrapper container">
            <br />


            <Row>
                <Col>
                    <h1 style={{ fontSize: '30px' }} className='orange-color-font font-family-ProximaNova'>
                        <b>
                            Login
                        </b>
                    </h1>
                    <br />
                    <form method='post' onSubmit={handleLogin}>

                        <div>
                            <label className='font-family-RalewayExtraBold' style={{ fontSize: '15px' }}>Username</label>
                            <input
                                className="form-control circule"
                                type="text"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                style={{ width: '400px', backgroundColor: '#e9eaec', marginTop: '8px' }}
                            />
                        </div>

                        <div style={{ marginTop: '16px' }}>
                            <label className='font-family-RalewayExtraBold' style={{ fontSize: '15px' }}>Password</label>
                            <input
                                className="form-control circule"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                style={{ width: '400px', backgroundColor: '#e9eaec', marginTop: '8px' }}
                            />
                        </div>

                        <br />
                        <br />
                        <br />
                        <button className='btn orange-color circule' style={{ width: '100px' }} type="submit">Login</button>

                    </form>
                    <br />
                    {error &&
                        <h4> <b><p style={{ color: 'red' }}>{error} </p></b></h4>

                    }
                </Col>
                <Col>

                    <h2 style={{ fontSize: '30px' }} className='orange-color-font font-family-ProximaNova'>
                        <b>
                            New User?
                        </b>
                    </h2>
                    <br />
                    <div>
                        <label className='font-family-RalewayBold'>If you have not created an account, please register</label>
                        <br /><br />
                        <button className='btn orange-color circule' type="button" onClick={handleRegister}>Register here</button>

                        <br/>
                        <br/>
                       
                    </div>
                    <b style={{color:'red'}}>{msg}</b>

                </Col>
            </Row>

        </div >
    );
};

export default Login;
