// import GameComponent from "../components/GameComponent"
import '../assets/css/index.css';
import FeedBackModalPopUp from '../components/FeedBackModalPopUp'
export default function GameApp() {

    return (
        <>
            <p className='large-text'>
                To get started, please watch this <a href="/tutorial">tutorial</a>.
            </p>
            <div>
                <p className='medium-text'>
                    If you find a bug or have any advice, please submit your feedback, including messages, screenshots, and screen recordings through the below form
                </p>
                <FeedBackModalPopUp />


            </div>
            <hr />
            {/* <div className="gamecontainer">
                <GameComponent />
            </div> */}
        </>

    )

}