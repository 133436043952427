import React, { useState } from 'react';
import { FaPlus, FaHome, FaQuestionCircle, FaRegComments, FaBook } from 'react-icons/fa';
import Modal from 'react-bootstrap/Modal';
import Feedback from '../pages/Feedback';
const FloatingComponent = () => {
    const [showItems, setShowItems] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const handleClick = () => {
        setShowItems(!showItems);
    };

    const handleItemClick = (item) => {
        if (item === 'Feedback') {
            setShowModal(true);
        } else {
            console.log(`${item} clicked`);
        }
    };

    const handleClose = () => {
        setShowModal(false);  // Close modal
    };



    return (
        <>
            {/* Floating Button */}
            <div className="floating-button-container">
                <button className="floating-button" onClick={handleClick}>
                    <FaPlus style={{ color: '#fff' }} />
                </button>
            </div>
            {/* Items to show on button click */}
            {showItems && (
                <div className="items-container">
                    <div className="item" onClick={() => handleItemClick('Home')}>
                        <a href='/'>
                            <FaHome className="item-icon" />
                        </a>
                        <span className="item-text">Home</span>
                    </div>
                    <div className="item" onClick={() => handleItemClick('FAQ')}>
                        <a href='/docs'>
                            <FaQuestionCircle className="floating-button-icon" />

                        </a>
                        <span className="item-text">FAQ</span>
                    </div>
                    <div className="item" onClick={() => handleItemClick('Feedback')}>
                        <FaRegComments className="item-icon" />
                        <span className="item-text">Feedback</span>
                    </div>
                    <div className="item" onClick={() => handleItemClick('Tutorial')}>
                        <a href='/tutorial'>
                            <FaBook className="item-icon" />

                        </a>
                        <span className="item-text">Tutorial</span>
                    </div>

                </div>


            )}

            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Submit Feedback Form</Modal.Title>
                </Modal.Header>
                <Modal.Body>


                    <Feedback />


                </Modal.Body>

            </Modal>
        </>
    );
}

export default FloatingComponent;
