import "../assets/css/index.css";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FeedBackModalPopUp from '../components/FeedBackModalPopUp'
export default function ContactUs() {
    return (
        <>
            <div className="content-wrapper container">

                <h1 className="orange-color-font">Get In Touch With Us.</h1>

                <p className="normal-text">We're here to assist you every step of the way. Feel free to reach out to us for any inquiries, support, or feedback.</p>
                <br />
                <br />

                <Row>

                    <Col>
                        <b className="large-text orange-color-font" > Support</b>
                        <br />

                        <p>
                            Our support team is here to promptly address any questions or concerns you might have. Feel free to reach out us via this email:
                            <a href="mailto: support@safetypoolstudio.ai">support@safetypoolstudio.ai</a>
                        </p>
                    </Col>
                    <Col>
                        <b className="large-text orange-color-font"> Feedback and Suggestions</b>
                        <p>
                            We deeply value your feedback and are constantly striving to enhance Safety Pool™ Studio. Your input is invaluable to us
                        </p>

                        <FeedBackModalPopUp />
                    </Col>
                    <Col>
                        <b className="large-text orange-color-font"> Location</b>
                        <br />
                        WMG <br />
                        National Automotive Innovation Centre <br />
                        Universtiy of Warwick <br />
                        Coventry <br />
                        United Kingdom



                    </Col>
                </Row>

            </div>

        </>
    )
}