import React from 'react';
import logowmgImg from '../images/logos/wmg.png';
import logoSpImg from '../images/logos/sp.png';
import logoURKIImg from '../images/logos/ukri.png';
import logoTCImg from '../images/logos/tc.png';
import logoCCAV from '../images/logos/ccav-logo.png';
import logoZenzic from '../images/logos/zenzic.png';
import logowmgImgFooter from '../images/logoswhite/wmg-white.png';
import logoSpImgFooter from '../images/logoswhite/sp-white.png';
import logoURKIImgFooter from '../images/logoswhite/ukri-white.png';
import 'react-slideshow-image/dist/styles.css'
import { Slide } from 'react-slideshow-image';


import Row from 'react-bootstrap/Row';
import './Card.css';

const Slideshow = ({ itemData }) => {
    return (
        <div className="slide-container">

            <Slide slidesToScroll={1} slidesToShow={5} indicators={false} duration={1000} arrows={false}>
                {itemData.map((item, index) => (
                    <div key={index}>
                        <div className="card-feature">

                            <a href={item.link}  >
                                <img
                                    style={{
                                        float: 'left',
                                        width: 'auto',
                                        height: '50px',
                                        objectFit: 'cover'
                                    }}
                                    src={item.imageUrl}
                                    alt={item.name}
                                />
                            </a>
                        </div>

                    </div>
                ))}
            </Slide>
        </div>

    )
}


const Item = ({ imageUrl, link, name, type }) => {

    return (
        <div className="card-feature">
            <a href={link}  >
                <img
                    style={{
                        float: 'left',
                        width: 'auto',
                        height: '30px',
                        objectFit: 'cover'
                    }}
                    src={imageUrl}
                    alt={name}
                />
            </a>
        </div>
    );
};



const FeaturedIn = ({ type }) => {
    let imageUrls;
    if (type === 'Home') {
        imageUrls = {
            wmg: logowmgImg,
            sp: logoSpImg,
            ukri: logoURKIImg,
            tc: logoTCImg,
            ccva: logoCCAV,
            zenzic:logoZenzic
        };
    } else if (type === 'Footer') {
        imageUrls = {
            wmg: logowmgImgFooter,
            sp: logoSpImgFooter,
            ukri: logoURKIImgFooter,
        };
    }

    const itemData = [
        { imageUrl: imageUrls.wmg, link: 'https://warwick.ac.uk/fac/sci/wmg/', name: 'WMG' },
        { imageUrl: imageUrls.sp, link: 'https://www.safetypool.ai', name: 'Safety Pool' },
        { imageUrl: imageUrls.ukri, link: 'https://www.ukri.org/', name: 'UKRI – UK Research and Innovation' },
        { imageUrl: imageUrls.tc, link: 'https://tc.canada.ca/en', name: 'Transport Canada', IsFooter: false },
        { imageUrl: imageUrls.ccva, link: 'https://www.gov.uk/government/organisations/centre-for-connected-and-autonomous-vehicles', name: 'Centre for Connected and Autonomous Vehicles', IsFooter: false },
        { imageUrl: imageUrls.zenzic, link: 'https://zenzic.io/', name: 'Zenzic ', IsFooter: false },

    ];

    const filteredItemData = type === 'Footer'
        ? itemData.filter(item => item.IsFooter !== false)
        : itemData;

    return (
        <div>
            <br />
            {type === 'Footer' && (
                <Row className="card-container no-gutters">
                    {filteredItemData.map((item, index) => (
                        <Item type={type} key={index} imageUrl={item.imageUrl} name={item.name} link={item.link} />
                    ))}
                </Row>
            )}
            {type === 'Home' && (

                <Slideshow itemData={itemData} />
            )}
        </div>
    );
};

export default FeaturedIn;

