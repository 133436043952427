import React from "react";
import { FaCarSide } from 'react-icons/fa';

const LoadingSpinner = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <FaCarSide
                style={{
                    fontSize: '3rem',
                    animation: 'moveLeftToRight 20s linear 1',
                }}
            />
            <b>
                <p style={{ marginTop: '10px', fontSize: '1.2rem' }}>
                    Loading, please wait...
                </p>
            </b>
        </div>
    );
}
export default LoadingSpinner;