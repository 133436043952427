import Navbar from "./components/Navbar";
import Landing from "./pages/Landing";
import Tutorial from "./pages/Tutorial";
import Home from "./pages/Home";
import About from "./pages/About";
import Dashboard from './pages/Admin/Dashboard';
import NotFound from './pages/Errors/NotFound';
import Unauthenticated from './pages/Errors/Unauthenticated';
import Forbidden from './pages/Errors/Forbidden';
import { Route, Routes } from "react-router-dom";
import Login from "./pages/Login"
import Privacy from "./pages/Privacy";
import ContactUs from "./pages/ContactUs";
import Docs from "./pages/Docs";
import ProtectedRoute from './components/admin/ProtectedRoute';
// import Footer from "./components/Footer";
import React, { lazy, Suspense } from 'react';
import FloatingComponent from "./components/FloatingComponent";
const Footer = lazy(() => import('./components/Footer'));


function App() {
  return (
    <>

      <div className='family-font app-wrapper'>
        <Navbar />
        <div >
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/home" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/tutorial" element={<Tutorial />} />
            <Route path="/login" element={<Login />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/docs" element={<Docs />} />
            <Route path="/dashboard" element={<ProtectedRoute element={<Dashboard />} adminRequired={true} />} />
            <Route path="/401" element={<Unauthenticated />} />
            <Route path="/403" element={<Forbidden />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
        <Suspense fallback={<div>Loading...</div>}>
          <Footer />
        </Suspense>
   
      </div>

      <FloatingComponent />
    </>
  )
}

export default App