import { Server_URL, protected_resource_URL } from '../../constants/constants';

export const isAuthenticated = async () => {
  const token = document.cookie.replace(/(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/, '$1');
  if (!token) {
    return false;
  }

  try {
    const response = await fetch(`${Server_URL}${protected_resource_URL}`, {
      method: 'GET',
      credentials: 'include', 
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();

    const result = {
      status: response.ok,
      role: data.user.role
    };
    return result;
  } catch (error) {
    console.error('Error checking authentication:', error);
    return false;
  }
};
