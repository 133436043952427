// ProtectedRoute.js
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { isAuthenticated as checkIsAuthenticated } from './isAuthenticated'; // Rename the imported function
import { Role } from '../../constants/constants';
const ProtectedRoute = ({ element, redirectToLogin = false, adminRequired = false}) => {
  const [loading, setLoading] = useState(true);
  const [isAuthenticatedState, setIsAuthenticatedState] = useState(false);
  const [isAdminRole,setIsAdminRole] = useState(false);

  useEffect(() => {
    const checkAuthentication = async () => {
      const result = await checkIsAuthenticated(); 
      setIsAuthenticatedState(result.status);
      if(result.role === Role.admin || result.role === Role.adminConsole)
      {
        setIsAdminRole(true);
      }
      setLoading(false);
    };

    checkAuthentication();
  }, []);

  if (loading) {
    return null; 
  }

  if (!isAuthenticatedState && redirectToLogin) {
    return <Navigate to="/login" />;
  }
  if (adminRequired && !isAdminRole) {
    return <Navigate to="/403" />;
  }

  return isAuthenticatedState ? element : <Navigate to="/401" />;
};

export default ProtectedRoute;
